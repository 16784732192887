import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import qq from "../assets/img/qq.svg";
import weibo from "../assets/img/weibo.svg";
import i_email from "../assets/img/email.svg";
import douyin from "../assets/img/douyin.svg";
import kuaishou from "../assets/img/kuaishou.svg";
import bilibili from "../assets/img/bilibili.svg";
import github from "../assets/img/github.svg";
import twitter from "../assets/img/twitter.svg";
export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
          <div className="social-icon">
            <div></div>
              <a href="http://sighttp.qq.com/authd?IDKEY=47ab3cb8cce7f356e0f5304374c4c340fc2d6dc0e32ec7a6" target="_blank"><img src={qq} alt="Icon" /></a>
              <a href="https://weibo.com/u/6233278720" target="_blank"><img src={weibo} alt="Icon" /></a>
              <a href="mailto:xmter@foxmail.com" target="_blank"><img src={i_email} alt="Icon" /></a>
              <a href="https://v.douyin.com/NqVhhDS" target="_blank"><img src={douyin} alt="Icon" /></a>
              <a href="https://www.kuaishou.com/profile/3xuu9vg97z2dtai" target="_blank"><img src={kuaishou} alt="Icon" /></a>
              <a href="https://space.bilibili.com/410721487" target="_blank"><img src={bilibili} alt="Icon" /></a>
              <a href="https://github.com/AooMing" target="_blank"><img src={github} alt="Icon" /></a>
            </div>
          </Row>
        </div>
      </Col>
  )
}
